// extracted by mini-css-extract-plugin
export var contactLink = "styles-module--contact-link--0nvy2";
export var container = "styles-module--container--+1Bs2";
export var description = "styles-module--description--YuJ9b";
export var descriptions = "styles-module--descriptions--wu6Sy";
export var destinations = "styles-module--destinations--8pMmW";
export var eheader = "styles-module--eheader--Gc9Jh";
export var espan = "styles-module--espan--X5jJq";
export var etitle = "styles-module--etitle--s1mhe";
export var imageContainer = "styles-module--image-container--hlX7w";
export var imageText = "styles-module--image-text--xuFDx";
export var locationIcon = "styles-module--location-icon--Mhq9t";
export var locationIcon2 = "styles-module--location-icon2--Rsfmy";
export var locationIcon3 = "styles-module--location-icon3--5cXbP";
export var overlay = "styles-module--overlay--adXyn";
export var overlayText = "styles-module--overlay-text--Rd+6y";
export var overlayText2 = "styles-module--overlay-text2--E1MK9";
export var overlayText3 = "styles-module--overlay-text3--2vUNB";