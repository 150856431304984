// extracted by mini-css-extract-plugin
export var btn = "styles-module--btn--PBWmc";
export var btnDiv = "styles-module--btn-div--o7Z6M";
export var ccontainer = "styles-module--ccontainer--k4PAw";
export var cform = "styles-module--cform--dLr5d";
export var contact = "styles-module--contact--WgNrD";
export var fcontainer = "styles-module--fcontainer--7Wund";
export var form = "styles-module--form--OFV2X";
export var formControl = "styles-module--form-control--9SQ3f";
export var header = "styles-module--header--X7agx";
export var heroContainer = "styles-module--hero-container--3rV9h";
export var img = "styles-module--img--y4JvU";
export var text = "styles-module--text--vweSM";