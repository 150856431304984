// extracted by mini-css-extract-plugin
export var contactLink = "styles-module--contact-link--RG88O";
export var container = "styles-module--container--RXSUn";
export var eheader = "styles-module--eheader--owxrE";
export var espan = "styles-module--espan--DOHWU";
export var etitle = "styles-module--etitle--ZtE9b";
export var sblock = "styles-module--sblock--p195Q";
export var sblock2 = "styles-module--sblock2--u9Jyy";
export var serviceSpan = "styles-module--service-span--XpnvG";
export var services = "styles-module--services--qVKpb";
export var simage = "styles-module--simage--RVBJg";