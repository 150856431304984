// extracted by mini-css-extract-plugin
export var border = "styles-module--border--VN+nm";
export var border1 = "styles-module--border1--ZT2Tr";
export var border2 = "styles-module--border2--kv8i-";
export var btn = "styles-module--btn--576Cw";
export var clogo = "styles-module--clogo--raCNl";
export var clogo2 = "styles-module--clogo2--tFLzI";
export var contact = "styles-module--contact--v3zLi";
export var flexLogo = "styles-module--flex-logo--EVpDR";
export var label = "styles-module--label--kVsv4";
export var logo = "styles-module--logo--6RQhP";
export var logo2 = "styles-module--logo2--SLeTp";
export var nav = "styles-module--nav--28F0o";
export var navInner = "styles-module--nav-inner--i2+91";
export var navbar = "styles-module--navbar--1JiXR";
export var navlink = "styles-module--navlink--ikwFS";
export var navlinks = "styles-module--navlinks--yPfLo";
export var number = "styles-module--number--iMqJF";
export var numberLink = "styles-module--number-link--kk9Vt";
export var toggle = "styles-module--toggle--RY8GT";
export var wnav = "styles-module--wnav--CgMbs";